/* --------------------
 * ResultSlider
 * -------------------- */
.ResultSlider {
	//some style
	position: relative;
	
	.ResultSlider__prev {
		position: absolute;
		top: 50%;
		left: 0;
		transform: translate(-50%, -50%);
		width: 20px;
		height: 20px;
		background-image: url(../images/common/icon_prev.png);
		background-position: center center;
		background-size: contain;
		background-repeat: no-repeat;
		z-index: 999;
	}

	.ResultSlider__next {
		position: absolute;
		top: 50%;
		right: 0;
		transform: translate(50%, -50%);
		width: 20px;
		height: 20px;
		background-image: url(../images/common/icon_next.png);
		background-position: center center;
		background-size: contain;
		background-repeat: no-repeat;
		z-index: 999;
	}

	.ResultSlider__items {
		background: #F8F5ED;
		margin-top: 20px;
		padding: 20px 20px;
	}

	.ResultSlider__item {
		font-size: 0;
		display: flex;
		flex-wrap: wrap;
		li {
			background: #fff;
			margin: 1.16%;
			width: 31%;
			height: 45px;
		}
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){

		.ResultSlider__prev {
			transform: translate(30%, -50%);
			width: 24px;
			height: 24px;
		}

		.ResultSlider__next {
			transform: translate(-30%, -50%);
			width: 24px;
			height: 24px;
		}

		.ResultSlider__items {
			margin-top: 30px;
			padding: 0 40px;
		}

		.ResultSlider__item {
			li {
				width: 17%;
				height: 45px;
				margin: 10px 10px;
			}

			font-size: 0;
			img {
				width: 100%;
			}
		}
	}
}