/* --------------------
 * Colx3c
 * -------------------- */
.Colx3c {
	@media print, screen and (max-width: $breakpoint1 - 1){
		padding: 0 10px;
	}

	.Colx3c__col {
		border: 3px solid #C3C3C3;
		border-radius: 11px;
		padding: 12px 14px;
		@media print, screen and (max-width: $breakpoint1 - 1){
			&:not(:first-child) {
				margin-top: 13px;
			}
		}
	}

	.Colx3c__title {
		font-size: 15px;
		color: #CA0010;
		text-align: center;
		font-weight: bold;
	}

	.Colx3c__intro {
		font-size: 13px;
		color: #262626;
		@include fontMedium;
		margin-top: 10px;
	}


	// PC style
	@media print, screen and (min-width: $breakpoint1){
		display: flex;

		.Colx3c__col {
			width: calc( (100% - 88px) / 3);
			padding: 12px 14px;
			box-sizing: border-box;
			margin-left: 22px;
			&:last-child {
				margin-right: 22px;
			}
		}

		.Colx3c__title {
			font-size: 24px;
		}

		.Colx3c__intro {
			font-size: 16px;
			margin-top: 10px;
		}
	}
}