/* --------------------
 * Qa
 * -------------------- */
.Qa {

	.Qa__title {
		font-size: 23px;
		font-weight: bold;
		text-align: center;
		line-height: 2.0;
	}

	.Qa__fontQa__intros {
		font-size: 12px;
	}

	.Qa__intros {
		background: rgba(0, 145, 209, 0.1);
		border-radius: 11px;
		margin-top: 13px;
		padding: 13px 13px;
	}

	.Qa__intro {
		display: flex;
		align-items: center;
		letter-spacing: 0;

		&.-qus {
			font-size: 13px;
			font-weight: bold;
			padding-right: 10px;
		}

		&.-ans {
			font-size: 12px;
			margin-top: 7px;
		}
	}

	.Qa__font {
		&.-f1 {
			color: #173CBA;
		}

		&.-f2 {
			position: relative;
			&:before {
				position: absolute;
				top: 0;
				left: 50%;
				transform: translate(-50%, -80%);
				content: '';
				background: #CA0010;
				width: 5px;
				height: 5px;
				border-radius: 50%;
			}
		}

		&.-f3 {
			font-size: 18px;
			font-weight: bold;
			color: #262626;
			width: 1.4em;
			display: inline-block;
		}

		&.-f4 {
			font-size: 18px;
			font-weight: bold;
			color: #CA0010;
			width: 1.4em;
			display: inline-block;
		}
	}


	// PC style
	@media print, screen and (min-width: $breakpoint1){
		.Qa__title {
			font-size: 32px;
		}

		.Qa__fontQa__intros {
			font-size: 15px;
		}

		.Qa__intros {
			margin-top: 42px;
			padding: 17px 17px 43px 171px;
			
			position: relative;
			&:before {
				display: block;
				position: absolute;
				bottom: 0;
				left: 0;
				content: '';
				background-image: url(../images/guide/qa_01.png);
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center bottom;
				width: 174px;
				height: 260px;	
			}
		}

		.Qa__intro {
			letter-spacing: 0;

			&.-qus {
				font-size: 20px;
				padding-right: 10px;
			}

			&.-ans {
				font-size: 16px;
				margin-top: 7px;
			}
		}

		.Qa__font {
			&.-f1 {
			}

			&.-f2 {
				&:before {
					width: 10px;
					height: 10px;
				}
			}

			&.-f3 {
				font-size: 30px;
				width: 1.4em;
			}

			&.-f4 {
				font-size: 30px;
				width: 1.4em;
			}
		}
	}
}