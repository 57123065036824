/* --------------------
 * Link
 * -------------------- */
.Link {
	display: flex;
	flex-wrap: wrap;

	.Link__item {
		border: 2px solid #C3C3C3;
		border-radius: 5px;
		width: calc((100% - 9px)/2);
		box-sizing: border-box;
		@media print, screen and (max-width: $breakpoint1 - 1){
			&:nth-child(even) {
				margin-left: 9px;
			}
			&:nth-child(n+3) {
				margin-top: 8px;
			}
		}

		a {
			display: flex;
			align-items: center;
			width: 100%;
			height: 100%;
			font-size: 13px;
			font-weight: bold;
			color: #262626;
			text-decoration: none;
			padding: 8px 9px;
			box-sizing: border-box;

			position: relative;
			&:before {
				position: absolute;
				top: 50%;
				right: 0;
				transform: translate(-104%, -50%) rotate(45deg);

				content: '';
				border-top: 2px solid #262626;
				border-right: 2px solid #262626;
				width: 5px;
				height: 5px;
			}

			img {
				width: 17px;
				margin-right: 8px;
			}
		}
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		.Link__item {
			width: calc((100% - 34px)/3); // 34 = 17px x 2
			&:not(:first-child):not(:nth-child(3n+1)) {
				margin-left: 17px;
			}
			
			&:nth-child(n+4) {
				margin-top: 15px;
			}

			a {
				font-size: 16px;
				padding: 14px 16px;

				&:before {
					top: 50%;
					right: 0;
					transform: translate(-104%, -50%) rotate(45deg);
					border-top: 3px solid #262626;
					border-right: 3px solid #262626;
					width: 9px;
					height: 9px;
				}

				img {
					width: 34px;
					margin-right: 16px;
				}
			}
		}
	}
}