/* --------------------
 * FooterNav
 * -------------------- */
.FooterNav{
	padding: 35px 20px;
	background: #1A1A1A;

	//some style
	.FooterNav__box {
		
		@media print, screen and (max-width: $breakpoint1 - 1){
			
			&:not(.-right) {
				border-bottom: 1px solid #4D4D4D;
				padding-bottom: 22px;
			}

			.FooterNav__links {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;

				&.-left {
					
				}

				&.-center {
					margin-top: 17px;
				}

				&.-right {
					margin-top: 17px;
				}
			}


			.FooterNav__link {
				width: 49%;
				margin-top: 19px;
			}

			&.-left {
				.FooterNav__link {
					&:first-child {
						width: 100%;
					}

					&:nth-child(n+2) {
						margin-top: 19px;
					}
				}
			}

			&.-center {
				margin-top: 17px;
				.FooterNav__links {
					display: block;
				}

				.FooterNav__link {
					width: auto;
				}
			}

			&.-right {
				margin-top: 17px;
			}
		}

		.FooterNav__link {
			a {
				font-size: 12px;
				font-weight: bold;
				color: #fff;
				text-decoration: none;
			}
		}

	}

	.FooterNav__expTitle {
		font-size: 12px;
		font-weight: bold;
		color: #fff;
	}

	.FooterNav__exps {
		margin-top: 19px;
		
		.FooterNav__link {
			a {
				font-weight: normal;
			}
			
			@media print, screen and (max-width: $breakpoint1 - 1){
				&:nth-child(1),
				&:nth-child(2) {
					margin-top: 0;
				}
			}

		}
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		display: flex;
		justify-content: center;
		padding: 46px 0;

		.FooterNav__box {
			&.-center {
				margin-left: 50px;
			}

			&.-right {
				margin-left: 50px;
			}
		}

		.FooterNav__contact {
			background: #CA0010;
			padding: 49px 0;
			text-align: center;
		}

		.FooterNav__link {
			&:not(:first-child) {
				margin-top: 10px;
			}

			a {
				font-size: 13px;
			}
		}

		.FooterNav__expTitle {
			font-size: 13px;
			border-bottom: 1px solid #4D4D4D;
			padding-bottom: 20px;
		}

		.FooterNav__exps {
			display: flex;
			.FooterNav__links {
				&.-left {
					
				}

				&.-center {
					margin-left: 42px;
				}

				&.-right {
					margin-left: 42px;
				}

			}
		}
	}
}