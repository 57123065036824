/* --------------------
 * Pagetitle
 * -------------------- */
.Pagetitle {
	//some style
	background-image: url(../images/common/pagetitle_bg.png);
	background-repeat: repeat-x;
	background-size: 100% 100%;
	background-position: top left;

	font-weight: bold;
	color: #fff;
	font-size: 15px;
	padding: 3px 0.58em;
	
	&.-mt1 {
		margin-top: 36px;
	}
	&.-mb1 {
		margin-bottom: 36px;
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		font-size: 24px;
		padding: 4px 0.58em;
		
		&.-mt1 {
			margin-top: 36px;
		}
	}
}