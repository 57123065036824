/* --------------------
 * Faq
 * -------------------- */
.Faq {

	.Faq__items {

	}

	.Faq__item {
		font-size: 13px;
		border-bottom: 1px solid #C3C3C3;
		padding-bottom: 10px;
		&:not(:first-child) {
			margin-top: 10px;
		}
	}

	.Faq__head {
		font-size: 15px;
		font-weight: bold;
		width: 28px;
		flex: 0 0 28px;
		height: 28px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		border-radius: 50%;
		margin-right: 7px;

		&.-qus {
			background: #CA0010;
		}

		&.-ans {
			background: #262626;
		}
	}

	.Faq__desc {
		display: flex;
		align-items: baseline;
		line-height: 2.0;

		&.-qus {
			color: #CA0010;
		}

		&.-ans {
			color: #262626;
			margin-top: 10px;
		}
	}

	.Faq__font {
		flex: 0 0 auto;
		&.-f1 {
			color: #CA0010;
			text-decoration: underline;
			a {
				color: #CA0010;
			}
		}
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		.Faq__items {

		}

		.Faq__item {
			font-size: 15px;
			padding-bottom: 19px;
			&:not(:first-child) {
				margin-top: 19px;
			}
		}

		.Faq__head {
			font-size: 18px;
			width: 34px;
			flex: 0 0 34px;
			height: 34px;
			margin-right: 9px;

			&.-qus {

			}

			&.-ans {

			}
		}

		.Faq__desc {
			&.-qus {

			}

			&.-ans {
				margin-top: 21px;
			}
		}
	}
}