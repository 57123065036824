/* --------------------
 * Asideleft
 * -------------------- */
.Asideleft {
	//some style
	
	@media print, screen and (max-width: $breakpoint1 - 1){
		margin-top: 44px;
	}

	.Asideleft__title {
		font-size: 14px;
		font-weight: bold;
		text-align: center;
		background: #1A1A1A;
		padding: 6px;
		color: #fff;
	}

	.Asideleft__image {
		font-size: 0;
	}

	.Asideleft__box {
		background: #F8F5ED;
		&.-event {
			margin-top: 0;
		}
		
		&.-privacy,
		&.-blog {
			margin-top: 34px;
		}
	}

	.Asideleft__exps {
	}

	.Asideleft__link {

		border-bottom: 1px solid #DBD7CD;
		
		a {
			display: flex;
			align-items: center;
			padding: 13px 10px;
			text-decoration: none;
			color: #1A1A1A;
			font-weight: bold;
			
			position: relative;
			&:after {
				position: absolute;
				top: 50%;
				right: 0;
				transform: translate(-150%, -50%) rotate(45deg);

				content: '';
				width: 7px;
				height: 7px;
				border-right: 3px solid #1A1A1A;
				border-top: 3px solid #1A1A1A;
			}
		}

		a.Asideleft__linkInner {
			padding: 15px 22px;
		}

		&.-privacy {
			border-bottom: none;
			a {
				padding: 28px 10px;
				font-size: 12px;
			}
		}
	}

	.Asideleft__btnwrap {
	}

	.Asideleft__btn {
		padding: 30px 20px;
	}

	img.Asideleft__icon {
		width: auto;
		margin-left: 10px;
		margin-right: 10px;
		width: 25px;
	}


	.Asideleft__blogimage {
		width: calc((100% - 14px) * 0.27);
		font-size: 0;
		img {
			width: 100%;
		}
	}

	.Asideleft__blogtitle {
		width: calc((100% - 14px) * 0.6);
		margin-left: 14px;
		font-size: 13px;
		max-height: 3.1em;
		overflow: hidden;
	}




	// PC style
	@media print, screen and (min-width: $breakpoint1){
		margin-bottom: 50px;
		
		.Asideleft__image {
			img {
				width: 100%;
			}
		}

		.Asideleft__box {
			margin-top: 22px;
		}

		.Asideleft__exps {
		}

		.Asideleft__blogimage {
		}

		.Asideleft__blogtitle {
		}

		.Asideleft__link {
			a.Asideleft__linkInner {
				padding: 15px 15px;
			}
		}

		img.Asideleft__icon {
		}

	}
}