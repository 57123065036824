/* --------------------
 * Colx1d
 * -------------------- */
.Colx1d {
	
	color: #262626;

	.Colx1d__col {
		border: 1px solid #C3C3C3;
		padding: 23px 31px;
		&:not(:first-child) {
			margin-top: 21px;
		}
	}

	.Colx1d__title {
		font-size: 14px;
		font-weight: bold;
		border-bottom: 1px dashed #C3C3C3;
		padding-bottom: 15px;
	}

	.Colx1d__box {
		margin-top: 17px;
	}

	.Colx1d__image {
		font-size: 0;
	}

	.Colx1d__right {
		@media print, screen and (max-width: $breakpoint1 - 1){
			margin-top: 1em;
		}
	}

	.Colx1d__loctitle {
		font-size: 13px;
		font-weight: bold;
		display: flex;
		align-items: center;
		img {
			margin-right: 5px;
			width: 10px;
		}
	}

	.Colx1d__loc {
		font-size: 13px;
		margin-top: 9px;
		line-height: 1.8;
	}

	.Colx1d__loclink {
		margin-top: 5px;
		a {
			color: #B10211;
			font-size: 13px;
		}
	}

	.Colx1d__teltitle {
		font-size: 13px;
		font-weight: bold;
		display: flex;
		align-items: center;
		margin-top: 9px;
		img {
			margin-right: 5px;
			width: 10px;
		}
	}

	.Colx1d__tel {
		font-size: 13px;
		margin-top: 9px;
	}

	.Colx1d__map {
		margin-top: 10px;
	}

	.Colx1d__items {
		border: 1px solid #C3C3C3;
		margin-top: 24px;
	}

	.Colx1d__item {
		display: flex;
		&:not(:last-child) {
			border-bottom: 1px solid #C3C3C3;
		}
	}

	.Colx1d__itemName {
		font-size: 12px;
		padding: 0.5em;
		width: 7em;
		background: #F9F9F9;
		font-weight: bold;
		flex: 0 0 auto;
	}

	.Colx1d__itemVal {
		font-size: 12px;
		padding: 0.5em;
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){

		.Colx1d__col {
			padding: 23px 31px;
			&:not(:first-child) {
				margin-top: 21px;
			}
		}

		.Colx1d__title {
			font-size: 22px;
			padding-bottom: 15px;
		}

		.Colx1d__box {
			display: flex;
			margin-top: 32px;
		}

		.Colx1d__image {
			font-size: 0;
			width: 292px;
			img {
				width: 100%;
			}
		}

		.Colx1d__right {
			margin-left: 26px;
		}

		.Colx1d__loctitle {
			font-size: 16px;
			img {
				margin-right: 7px;
				width: 14px;
			}
		}

		.Colx1d__loc {
			font-size: 16px;
			margin-top: 8px;
		}

		.Colx1d__loclink {
			margin-top: 4px;
			a {
				font-size: 16px;
			}
		}

		.Colx1d__teltitle {
			font-size: 16px;
			margin-top: 8px;
			img {
				margin-right: 7px;
				width: 14px;
			}
		}

		.Colx1d__tel {
			font-size: 16px;
			margin-top: 8px;
		}

		.Colx1d__map {
			margin-top: 10px;
		}

		.Colx1d__items {
			margin-top: 24px;
		}

		.Colx1d__item {
			&:not(:last-child) {
			}
		}

		.Colx1d__itemName {
			font-size: 13px;
			padding: 15px 21px;
			width: 9em;
			flex: 0 0 auto;
		}

		.Colx1d__itemVal {
			font-size: 14px;
			padding: 12px 28px;
		}
	}
}