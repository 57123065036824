/* --------------------
 * Bmenu
 * -------------------- */
.Bmenu {
	border-bottom: 1px solid #E6E6E6; 

	.Bmenu__nav {
		font-size: 12px;
	}

	.Bmenu__links {
		display: flex;
		flex-wrap: wrap;
	}

	.Bmenu__link {
		width: 50%;
		box-sizing: border-box;
		padding: 19px 13px;

		border-bottom: 1px solid #E6E6E6; 

		&:nth-child(odd) {
			border-right: 1px solid #E6E6E6; 
		}

		a {
			color: #262626;
			font-weight: bold;
			text-decoration: none;
		}
	}

	.Bmenu__images {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		border-bottom: 1px solid #E6E6E6; 
	}

	.Bmenu__image {
		font-size: 0;
		width: 50%;
		box-sizing: border-box;
		border-bottom: 1px solid #E6E6E6; 

		&:nth-child(odd) {
			border-right: 1px solid #E6E6E6; 
		}

		a {
		}
	}

	.Bmenu__telBox {
		position: relative;
		border-bottom: 1px solid #E6E6E6; 
	}

	.Bmenu__tel {
    padding: 13px 10px;
	}

	.Bmenu__teltext {
		top: 10.1%;
		left: 26.2%;

		position: absolute;
		a {
			font-size: 10.3vw;
			font-family: 'Roboto', sans-serif;
			font-style: normal;
			font-weight: bold;
			color: #CA0010;
			letter-spacing: -0.01em;
			text-decoration: none;
		}
	}

	.Bmenu__contact {
		border-bottom: 1px solid #E6E6E6; 
		padding: 13px 10px;
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){

	}
}