/* --------------------
 * Colx1
 * -------------------- */
.Colx1 {
	//some style
	.Colx1__intro {
		font-size: 12px;
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		.Colx1__intro {
			font-size: 15px;
		}
	}
}