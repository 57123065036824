/* --------------------
 * Subtitle
 * -------------------- */
.Subtitle {
	
	&.-mt33 {
		margin-top: 33px;
	}

	&.-mt58 {
		margin-top: 58px;
	}

	&.-black {
		color: #262626;
	}

	font-weight: bold;
	color: #CA0010;
	font-size: 15px;
	padding: 2px 6px;
	border-left: 4px solid #CA0010;

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		font-size: 22px;
		padding: 0px 16px;
		border-left: 4px solid #CA0010;
	}
}