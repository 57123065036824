/* --------------------
 * Mainav
 * -------------------- */
.Mainav {
	//some style
	.Mainav__links {
	}

	.Mainav__link {
		font-size: 0;
		@media print, screen and (max-width: $breakpoint1 - 1){
			&:not(:first-child) {
				margin-top: 10px;
			}
		}
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		.Mainav__links {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		.Mainav__link {
			width: calc((100% - 10px) / 2);

			&:nth-child(n+3) {
				margin-top: 10px;
			}

			img {
				width: 100%;
			}
		}
	}
}