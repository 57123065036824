/* --------------------
 * Main
 * -------------------- */
.Main {
	//some style
	.Main__mvBox {
		position: relative;
	}

	.Main__mv {
		height: 280px;
		font-size: 0;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
	}

	.Main__mvtext {
		position: absolute;
		top: 50%;
		right: 50%;
		transform: translate(50%, -50%);
		width: 96%;
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		.Main__mv {
			height: 400px;
		}

		.Main__mvtext {
			width: 800px;
			img {
				width: 100%;
			}
		}

		.Main__contents {
			max-width: 1250px;
			margin: 51px auto 0;
		}
	}
}