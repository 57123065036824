/* --------------------
 * Colx2b
 * -------------------- */
.Colx2b {
	color: #262626;

	.Colx2b__col {
		border: 1px solid #C3C3C3;
		padding: 12px 10px;
		box-sizing: border-box;
		@media print, screen and (max-width: $breakpoint1 - 1){
			&:not(:first-child) {
				margin-top: 1em;
			}
		}
	}

	.Colx2b__image {
		font-size: 0;
		img {
			width: 100%;
		}
	}

	.Colx2b__colLow {

	}

	.Colx2b__coltitle {
		font-size: 15px;
		font-weight: bold;
		border-bottom: 1px dashed #C3C3C3;
		padding-bottom: 5px;
		margin-top: 11px;
	}

	.Colx2b__colintro {
		font-size: 12px;
		margin-top: 6px;
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		display: flex;
		justify-content: space-between;

		.Colx2b__col {
			width: calc((100% - 12px)/2);
			padding: 24px 20px;
		}

		.Colx2b__image {
			img {
				width: 100%;
			}
		}

		.Colx2b__colLow {

		}

		.Colx2b__coltitle {
			font-size: 21px;
			padding-bottom: 10px;
			margin-top: 22px;
		}

		.Colx2b__colintro {
			font-size: 15px;
			margin-top: 11px;
		}
	}
}