/* --------------------
 * Paginatec
 * -------------------- */
.Paginatec {

	.Paginatec__next,
	.Paginatec__arch,
	.Paginatec__prev {
		border: 1px solid #DBD7CD;
		width: 90%;
		border-radius: 10px;
		@media print, screen and (max-width: $breakpoint1 - 1){
			margin-left: auto;
			margin-right: auto;
		}
		a {
			padding: 10px 0;
			display: flex;
			justify-content: center;
			align-items: center;
			text-decoration: none;
			color: #222;
		}

		p {
			margin: 0;
			font-size: 13px;
		}
	}

	.Paginatec__next,
	.Paginatec__prev {
		position: relative;
		&:before {
			content: '';
			width: 7px;
			height: 7px;
			border-top: 2px solid #CA0010;
			position: absolute;
			top: 50%;
		}
	}

	.Paginatec__next {
		&:before {
			border-right: 2px solid #CA0010;
			transform: translate(-230%, -50%) rotate(45deg);
			right: 0;
		}
	}

	.Paginatec__prev {
		&:before {
			border-left: 2px solid #CA0010;
			left: 0;
			transform: translate(230%, -50%) rotate(-45deg);
		}
	}

	.Paginatec__next,
	.Paginatec__arch {
		@media print, screen and (max-width: $breakpoint1 - 1){
			margin-top: 1em;
		}
	}

	.Paginatec__link {

	}

	.Paginatec__text {

	}







	// PC style
	@media print, screen and (min-width: $breakpoint1){
		display: flex;
		justify-content: space-between;
		position: relative;


		.Paginatec__next,
		.Paginatec__arch,
		.Paginatec__prev {
			width: 211px;
			a {
				padding: 10px 0;
			}

			p {

			}
		}

		.Paginatec__next,
		.Paginatec__prev {
			position: relative;
			&:before {
				width: 7px;
				height: 7px;
			}
		}

		.Paginatec__next {
			&:before {
				transform: translate(-230%, -50%) rotate(45deg);
			}
		}

		.Paginatec__prev {
			&:before {
				transform: translate(230%, -50%) rotate(-45deg);
			}
		}

		.Paginatec__arch {
			position: absolute;
			top: 50%;
			right: 50%;
			transform: translate(50%, -50%);
		}

		.Paginatec__next {

		}

		.Paginatec__link {

		}

		.Paginatec__text {

		}

	}
}