/* --------------------
 * Colx1b
 * -------------------- */
.Colx1b {

	&.-mt18 {
		margin-top: 18px;
	}

	&.-mt31 {
		margin-top: 31px;
	}

	//some style
	.Colx1b__col {
		display: flex;
		justify-content: space-between;
		padding: 10px 9px 22px;
		border: 1px solid #C3C3C3;
		&:not(:first-child) {
			margin-top: 21px;
		}
		&:nth-child(even) {
			background: #F8F5ED; 
		}
	}

	.Colx1b__left {
		@media print, screen and (max-width: $breakpoint1 - 1){
			width: calc( (100% - 15px) * 0.65);
		}
	}

	.Colx1b__titleBox {
		border-bottom: 1px dashed #C3C3C3;
		padding-bottom: 13px;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}

	.Colx1b__title {
		font-size: 15px;
		font-weight: bold;
		color: #CA0010;
	}

	.Colx1b__tag {
		background: #CA0010;
		color: #fff;
		font-size: 13px;
		font-weight: bold;
		display: flex;
		align-items: center;
		padding: 0 7px;
		flex: 0 0 auto;
	}

	.Colx1b__intro {
		font-size: 12px;
		margin-top: 12px;
		letter-spacing: 0;
	}

	.Colx1b__right {
		@media print, screen and (max-width: $breakpoint1 - 1){
			width: calc( (100% - 15px) * 0.35);
		}
	}

	.Colx1b__image {
		font-size: 0;
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		.Colx1b__col {
			font-size: 15px;
			padding: 24px 28px;
			&:not(:first-child) {
				margin-top: 21px;
			}
		}

		.Colx1b__left {
			width: 422px;
			width: calc( (100% - 34px) * 0.63);
		}

		.Colx1b__titleBox {
			padding-bottom: 13px;
		}

		.Colx1b__title {
			font-size: 21px;
		}

		.Colx1b__tag {
			font-size: 17px;
			padding: 0 7px;
		}

		.Colx1b__intro {
			font-size: 15px;
			margin-top: 12px;
		}

		.Colx1b__right {
			width: calc( (100% - 34px) * 0.37);
		}

		.Colx1b__image {
			img {
				width: 100%;
			}
		}
	}
}