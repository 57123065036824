/* --------------------
 * ItemDetail
 * -------------------- */
.ItemDetail {
	.-spmt0 {
		margin-top: 0 !important;
	}

	margin-top: 5px;

	.ItemDetail__subtitle {
		margin-top: 12px;
	}
	
	.ItemDetail__images{
		margin: 12px 0;
		
		@media print, screen and (max-width: $breakpoint1 - 1){
			img {
				&:not(:first-child) {
					margin-top: 12px;
				}
			}
		}
	}

	.ItemDetail__image{
		font-size: 0;
		margin: 12px 0;
		&.-width3{
			width: 160px;
			margin: 12px auto;
		}
	}
	
	.ItemDetail__3colItem{
		&.-width2{
			width: 240px;
			margin: 12px auto;
		}
	}

	.ItemDetail__2col{
		margin-top: 12px;
	}
	
	.ItemDetail__2bcol{
		margin-top: 12px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.ItemDetail__2bcolItem{
		&.-o1 {
			max-width: calc((100% - 15px) * 0.1);
			flex: 0 0 calc((100% - 15px) * 0.1);
		}

		&.-o9 {
			max-width: calc((100% - 15px) * 0.9);
			flex: 0 0 calc((100% - 15px) * 0.9);
		}
	}


	
	@media print, screen and (max-width: $breakpoint1 - 1){
		.ItemDetail__2colItem{
			&:not(:first-child) {
				margin-top: 12px;
			}
		}
	}

	.ItemDetail__list {
		li {
			text-indent: -1em;
			margin-left: 1em;
			font-size: 12px;
			&:before {
				content: '・';
				color: #e40010;
			}
		}
	}

	.ItemDetail__title {
		&.-tbl {
			margin-top: 12px;
			&:before {
				padding-right: 0.5em;
				content: '■';
				color: #e40010;
			}
		}

		&.-apspec {
			font-size: 14px;
			font-weight: bold;
			margin-top: 1em;
		}

		&.-apsupport {
			font-size: 14px;
			font-weight: bold;
			color: #e40010;
			margin-top: 1em;
		}
	}

	.ItemDetail__figcap {
		font-size: 11px;
	}

	.ItemDetail__tbl {
		margin-top: 5px;
	}

	.ItemDetail__tblrow {
		display: flex;
		border-bottom: 1px solid #C3C3C3;
		
		&.-title {
			font-weight: bold;
			background: #F9F9F9;
			border-top: 1px solid #C3C3C3;
			
			.-val,
			.-name {
				justify-content: center;
			}
		}
	}

	.ItemDetail__tblitem {
		display: flex;
		&.-name {
			width: 50%;
			padding: 5px 15px;
		}

		&.-val {
			width: 50%;
			padding: 5px 15px;
			border-left: 1px solid #C3C3C3;
		}
	}

	.ItemDetail__item {
		&.-tblintro {
			margin-top: 12px;
		}
	}

	.-special {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;

		&:nth-child(2),
		&:nth-child(1) {
			.ItemDetail__image {
				margin-top: 0;
			}
		}

		.ItemDetail__image {
			margin-bottom: 0;
		}

		@media print, screen and (max-width: $breakpoint1 - 1){
			.ItemDetail__3colItem{
				&:nth-child(6),
				&:nth-child(5),
				&:nth-child(3),
				&:nth-child(1) {
					img {
						width: 50%;
					}
				}
			}
		}
	}
	// end -special

	.-special2{
		&.ItemDetail__images {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
		}
		
		img {
			width: calc((100% - 10px)/2);
			&:nth-child(n+3) {
				margin-top: 10px;
			}
		}
	}

	.-special3 {
		.ItemDetail__tblitem {
			display: flex;
			&.-name {
				width: 33%;
				padding: 5px 15px;
			}

			&.-val {
				width: 33%;
				padding: 5px 15px;
				border-left: 1px solid #C3C3C3;
			}
		}
	}

	.-special4 {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;

		.ItemDetail__3colItem {
			.ItemDetail__image {
				margin-bottom: 0;
			}
		}
	}

	.-wnowrap {
		white-space: nowrap;
	}


	// PC style
	@media print, screen and (min-width: $breakpoint1){
		margin-top: 9px;

		.-pcmt0 {
			margin-top: 0 !important;
		}

		.ItemDetail__title {
			&.-tbl {
				margin-top: 25px;
			}

			&.-apspec {
				font-size: 20px;
			}

			&.-apsupport {
				font-size: 20px;
			}

		}

		.ItemDetail__subtitle {
			margin-top: 25px;
		}

		.ItemDetail__images{
			margin: 25px 0;
			
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			align-items: flex-start;
			img {
				width: calc((100% - 15px)/2);
				
				&:not(.-o3):not(.-o5):not(.-o25) {
					&:nth-child(n+3) {
						margin-top: 25px;
					}
				}

				&.-o25 {
					width: calc((100% - 15px) * 0.25);
				}

				&.-o3 {
					width: calc((100% - 15px) * 0.333);
				}

				&.-o5 {
					width: calc((100% - 15px) * 0.5);
				}

				&.-o6 {
					width: calc((100% - 15px) * 0.666);
				}
			}

		}

		.ItemDetail__image{
			font-size: 0;

			margin: 25px 0;

			&.-width3{
				width: auto;
				margin: 25px auto;
			}
			img{
				max-width: 100%;
				width: auto;
			}
		}

		.ItemDetail__tbl {
			margin-top: 10px;
		}

		.ItemDetail__figcap {
			font-size: 13px;
		}

		.ItemDetail__3col{
			margin: 25px 0;
			display: flex;
			justify-content: space-between;
		}

		.ItemDetail__3colItem{
			flex: 0 0 33%;
			max-width: 33%;

			&.-width1{
				flex: 0 0 42%;
				max-width: 42%;
			}
			&.-width2{
				flex: 0 0 25%;
				max-width: 25%;
			}
		}

		.ItemDetail__2col{
			margin-top: 25px;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
		}

		.ItemDetail__2colItem{
			flex: 0 0 calc((100% - 15px)/2);
			max-width: calc((100% - 15px)/2);

			&.-width100{
				flex: 0 0 100%;
				max-width: 100%;
			}

			&.ItemDetail__tbl {
				margin-top: 0;
			}
		}

		.ItemDetail__list {
			li {
				font-size: 15px;
			}
		}

		.ItemDetail__tblrow {
			display: flex;
			border-bottom: 1px solid #C3C3C3;
			
			&.-title {
				font-weight: bold;
				background: #F9F9F9;
				border-top: 1px solid #C3C3C3;
				
				.-val,
				.-name {
					justify-content: center;
				}
			}
		}

		.ItemDetail__tblitem {
			display: flex;
			&.-name {
				width: 50%;
				padding: 5px 15px;
			}

			&.-val {
				width: 50%;
				padding: 5px 15px;
				border-left: 1px solid #C3C3C3;
			}
		}

		.ItemDetail__item {
			&.-tblintro {
				margin-top: 25px;
			}
		}

		.-special {
			justify-content: flex-start;
			flex-wrap: wrap;

			.ItemDetail__3colItem{
				flex: 0 0 calc((100% - 60px) * 0.333);
				max-width: calc((100% - 60px) * 0.333);

				&.-x2 {
					flex: 0 0 calc((100% - 60px) * 0.666);
					max-width: calc((100% - 60px) * 0.666);
				}

				&:nth-child(6),
				&:nth-child(4),
				&:nth-child(2) {
					margin-left: 30px;
				}
			}
		}

		.-special2{
			&.ItemDetail__images {
				justify-content: flex-start;
				img {
					&:nth-child(n+3) {
						margin-top: 0;
					}

					&:nth-child(n+5) {
						margin-top: 12px;
					}
				}
			}
			
			img {
				width: calc((100% - 45px)/4);
				&:not(:first-child):not(:nth-child(4n+1)) {
					margin-left: 15px;
				}
			}
		}

		.-special3 {

			.ItemDetail__tblitem {
				display: flex;
				justify-content: center;
				&.-name {
					width: 33%;
					padding: 5px;
				}

				&.-val {
					width: 33%;
					padding: 5px;
					border-left: 1px solid #C3C3C3;
				}
			}
		}

		.-special4 {
			justify-content: flex-start;
			flex-wrap: wrap;

			.ItemDetail__3colItem{
				flex: 0 0 calc((100% - 30px) * 0.333);
				max-width: calc((100% - 30px) * 0.333);

				&:not(:first-child):not(:nth-child(3n+1)) {
					margin-left: 15px;
				}
			}
		}

		.-special5 {
			&.ItemDetail__images {
				align-items: flex-start;
				img:nth-child(2) {
					margin-top: 13px;
				}
			}
		}

		.-wnowrap {
			white-space: nowrap;
		}
	}
}