/* --------------------
 * Mainv
 * -------------------- */
.Mainv {
	
	.Mainv__image {
		font-size: 0;
		margin-top: 31px;
	}

	.Mainv__title {
		font-size: 20px;
		font-weight: bold;
		background-image: url(../images/price/mainv_bg.png);
		background-repeat: no-repeat;
		background-position: top center;
		background-size: contain;
		margin: 0 auto;
		line-height: 1.0;
		text-align: center;
		padding-top: 8px;
		margin-top: 37px;
	}

	.Mainv__underline {
		background: linear-gradient(transparent 50%, #FFF100 50%);
		display: inline-block;
	}

	.Mainv__items {
		margin-top: 27px;
	}

	.Mainv__item {
		font-size: 14px;
		font-weight: bold;
		display: flex;
		letter-spacing: 0;
		align-items: center;
		&:not(.-long) {
			box-sizing: border-box;
		}

		&:not(:first-child) {
    	margin-top: 18px;
		}
	}

	.Mainv__ihead {
		width: 27px;
		margin-right: 13px;
	}


	// PC style
	@media print, screen and (min-width: $breakpoint1){

		.Mainv__image {
			margin-top: 31px;
			img {
				width: 100%;
			}
		}

		.Mainv__title {
			font-size: 32px;
			width: 500px;
			padding-top: 8px;
			margin-top: 37px;
		}

		.Mainv__underline {
		}

		.Mainv__items {
			margin-top: 27px;
		}

		.Mainv__item {
			font-size: 19px;
			display: flex;
			&:not(.-long) {
				width: 50%;
			}

			&:nth-child(n+3) {
	    	margin-top: 18px;
			}
		}

		.Mainv__ihead {
			width: 36px;
			margin-right: 13px;
		}
	}
}