/* --------------------
 * Header
 * -------------------- */
.Header{
	//some style
	
	.Header__row {
		padding: 8px 18px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-top: 4px solid #CA0010;
		border-bottom: 2px solid #E6E6E6;
	}

	.Header__left {
		font-size: 0;
	}

	.Header__logo {
		@media print, screen and (max-width: $breakpoint1 - 1){
			width: 273px;
		}
	}

	.Header__siteName {
		font-size: 12px;
		font-weight: bold;
		@media print, screen and (max-width: $breakpoint1 - 1){
			text-align: center;
			padding: 10px 0;
		}
	}

	.Header__by {
		@media print, screen and (max-width: $breakpoint1 - 1){
			width: 75px;
			margin-top: 2px;
		}
	}

	.Header__box {
		
	}

	.Header__right {
		
	}

	.Header__tel {
		
	}

	.Header__contact {
		
	}

	.Header__menu {
		font-size: 0;
		width: 25px;
	}

	.Header__bmenu {
		display: none;
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		
		.Header__row {
			padding: 18px 51px;
		}

		.Header__left {
			display: flex;
		}

		.Header__logo {
			width: 410px;
			img {
				width: 100%;
			}
		}

		.Header__box {
			margin-left: 15px;
			justify-content: space-between;
			display: flex;
			flex-direction: column;
		}

		.Header__siteName {
			font-size: 13px;
		}

		.Header__by {
			img {
				width: 116px;
			}
		}

		.Header__right {
			justify-content: space-between;
			display: flex;
		}

		.Header__telBox {
			position: relative;
		}

		.Header__tel {
			font-size: 0;
			img {
				width: 262px;
			}
		}

		.Header__teltext {
			a {
				font-size: 31px;
				@include english(bold);
				color: #CA0010;

				position: absolute;
				top: 0;
				left: 56px;
				letter-spacing: -0.01em;
				text-decoration: none;
			}
		}

		.Header__contact {
			margin-left: 16px;
		}

	}
}