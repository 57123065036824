/* --------------------
 * Contentsarea
 * -------------------- */
.Contentsarea {
	//some style

	.Contentsarea__main {
		padding: 0 20px;
	}


	@media print, screen and (max-width: $breakpoint1 - 1){

		.Contentsarea__asideleft {
			padding: 0 20px 36px;
		}

		.Contentsarea__asideright {
		}

	}


	// PC style
	@media print, screen and (min-width: $breakpoint1){
		display: flex;

		.Contentsarea__asideleft {
			order: 1;
			width: 225px;
			flex: 0 0 225px;
		}

		.Contentsarea__main {
			order: 2;
			padding-bottom: 62px;
			// width: calc(100% - 450px); // 450 = 225 * 2 (left, right)
			min-width: 800px;
			box-sizing: border-box;
		}

		.Contentsarea__asideright {
			order: 3;
			width: 225px;
			flex: 0 0 225px;
		}
	}
}