/* --------------------
 * Blog
 * -------------------- */
.Blog {
	
	h1 {
		display: block;
		margin-block-start: 0.67em;
		margin-block-end: 0.67em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		font-weight: bold;

		font-size: 2em;
		margin: 0.67em 0;
	}

	h2 {
		display: block;
		font-size: 1.5em;
		margin-block-start: 0.83em;
		margin-block-end: 0.83em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		font-weight: bold;

		font-size: 18px;
		font-weight: normal;
		letter-spacing: 2px;
		margin-bottom: 10px;
	}

	h3 {

	}

	h4 {

	}

	p {
		clear: both;
		font-size: 13px;
		margin-bottom: 15px;
	}

	img {
		max-width: 100%;
		height: auto;
	}

	ul {
		list-style-type: disc;
		list-style-position: inside;
	}

	ol {
		list-style-type: decimal;
		list-style-position: inside;
	}

	blockquote {
		position: relative;
		padding: 8px 15px 8px 15px;
		box-sizing: border-box;
		font-style: italic;
		background: #efefef;
		color: #555;
		margin-bottom: 20px;
	}

	strong {
		font-weight: bold;
	}

	#toc_container a {
		text-decoration: none;
		text-shadow: none;
		color: #333;
		&:hover {
			text-decoration: underline;
			color: #ca0010;
		}
	}

	a:link {
		color: #333;
		transition: 0.3s;
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
	
		h1 {

		}

		h2 {
			font-size: 28px;
			margin-bottom: 15px;
		}

		h3 {

		}

		h4 {

		}

		p {
			font-size: 15px;
			margin-bottom: 20px;
		}

		img {

		}

		ul {

		}

		ol {

		}

		blockquote {

		}

		strong {

		}

		#toc_container a {

		}

		a:link {

		}




	}
}