/* --------------------
 * Colx6
 * -------------------- */
.Colx6 {
	// display: flex;
	// flex-wrap: wrap;

	.Colx6__col {
		font-size: 0;
		@media print, screen and (max-width: $breakpoint1 - 1){
		}
	}

	.slick-slide {
		&:not(:first-child) {
			margin-left: 10px;
		}
	}

	.slick-current .Colx6__col {
		position: relative;
		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: 4px solid #CA0010;
			box-sizing: border-box;
		}
	}


	// PC style
	@media print, screen and (min-width: $breakpoint1){
		.Colx6__col {
			img {
				width: 100%;
			}
		}
		
		.slick-slide {
			&:not(:first-child) {
				margin-left: 10px;
			}
		}

	}
}