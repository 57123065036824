/* --------------------
 * Table
 * -------------------- */
.Table {
	border: 1px solid #C3C3C3;
	
	.Table__item {
		display: flex;
		&:not(:last-child) {
			border-bottom: 1px solid #C3C3C3;
		}
	}

	.Table__itemName {
		font-size: 12px;
		padding: 0.5em;
		width: 7em;
		background: #F9F9F9;
		font-weight: bold;
		flex: 0 0 auto;
	}

	.Table__itemVal {
		font-size: 12px;
		padding: 0.5em;
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		.Table__itemName {
			font-size: 14px;
			padding: 15px 21px;
			width: 9em;
			flex: 0 0 auto;
		}

		.Table__itemVal {
			font-size: 14px;
			padding: 12px 28px;
		}

	}
}