/* --------------------
 * Colx2
 * -------------------- */
.Colx2 {
	//some style
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	
	.Colx2__col {
		width: calc((100% - 10px) / 2);
		border: 1px solid #C3C3C3;
		border-top: 5px solid #CA0010;
		box-sizing: border-box;
		padding: 10px 9px;

		&:nth-child(n+3) {
			margin-top: 15px;
		}
	}

	.Colx2__image {
		line-height: 1.0;
		img {
			width: 100%;
		}
	}

	.Colx2__intro {
		font-size: 12px;
		margin-top: 9px;
	}

	&.-mt1 {
		margin-top: 20px;
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		.Colx2__col {
			width: calc((100% - 10px) / 2);
			padding: 18px 19px;
			&:nth-child(n+3) {
				margin-top: 20px;
			}

		}

		.Colx2__image {
		}

		.Colx2__intro {
			font-size: 15px;
			margin-top: 17px;
		}

		&.-mt1 {
			margin-top: 30px;
		}

	}
}