/* --------------------
 * Colx3d
 * -------------------- */
.Colx3d {
	@media print, screen and (max-width: $breakpoint1 - 1){
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	
	.Colx3d__col {
		@media print, screen and (max-width: $breakpoint1 - 1){
			width:  calc((100% - 11px) / 2);
		}
		padding-bottom: 22px;
		color: #262626;
		border: 1px solid #C3C3C3;
		padding: 10px 9px;
		text-align: center;
		box-sizing: border-box;
		
		@media print, screen and (max-width: $breakpoint1 - 1){
			&:nth-child(n+3) {
				margin-top: 16px;
			}
		}
	}

	.Colx3d__imageBox {
		position: relative;
	}

	.Colx3d__colLowItem {
		font-size: 12px;
		color: #262626;
	}

	.Colx3d__image {
		font-size: 0;
		img {
			width: 100%;
		}
	}

	.Colx3d__colLow {
		margin-top: 4px;
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		display: flex;
		flex-wrap: wrap;

		.Colx3d__col {
			width: calc((100% - 22px) / 3);
			display: block;
			padding: 0 0 16px;
			padding: 22px 19px 13px;

			&:nth-child(n+4) {
				margin-top: 23px;
			}

			&:not(:first-child):not(:nth-child(4n)) {
				margin-left: 11px;
			}
		}

		.Colx3d__imageBox {
			&:before {
				border-width: 69px 69px 0 0;
			}
		}
		
		.Colx3d__colLowItem {
			font-size: 15px;
		}

		.Colx3d__image {
			img {
			}
		}

		.Colx3d__colLow {
			margin-top: 8px;
		}
	}
}