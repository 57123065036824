/* --------------------
 * Footer
 * -------------------- */
.Footer{
	//some style
	.Footer__contact {
		background: #CA0010;
		padding: 39px 20px;
		figure {
			font-size: 0;
			img {
			}
		}
	}

	.Footer__row {
		border-top: 1px solid #4D4D4D;
		background: #1A1A1A;
		padding: 41px 0;
	}

	.Footer__siteName {
		font-size: 11px;
		font-weight: bold;
		text-align: center;
		color: #fff;
	}

	.Footer__copyright {
		font-size: 10px;
		font-weight: bold;
		text-align: center;
		color: #fff;
		margin-top: 8px;
	}
	
	@media print, screen and (max-width: $breakpoint1 - 1){
		.Footer__contactInner {
			position: relative;
		}

		.Footer__contactBox {
			position: absolute;
			bottom: 0.7%;
			left: 50%;
			transform: translateX(-50%);
			width: 99%;
		}

		.Footer__contactBox1 {
			padding-top: 59.7%;
			position: relative;
		}

		.Footer__contactBox2 {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		.Footer__contactLink {

			&.-tel {
				position: relative;
				width: 208px;
				margin: 0 auto;
				margin-bottom: 7.3%;
				figure {
					text-align: center;
					img {
					}
				}
				p {
					position: absolute;
					top: 50%;
					right: -1px;
					transform: translateY(-50%);
				}
				a {
					text-decoration: none;
					color: #fff;
					font-size: 26px;
					@include english(bold);
					letter-spacing: 0.01em;
				}
			}
			// end tel
			&.-contact {
				padding-top: 8.8%;
				figure {
					width: 139px;
					margin: 0 auto;
				}
				p {
					width: 245px;
					margin: 4% auto 0;
				}
				a {
					padding: 9px;
					background: #69b80e;
					font-size: 12px;
				}
				
				position: relative;
				&:before {
					position: absolute;
					top:0;
					left: 50%;
					transform: translateX(-50%);

					content: '';
					width: 100%;
					height: 1px;
					background: url('../../assets/images/common/footer_dotline.png');
					background-size: cover;
					background-repeat: repeat-x;
					background-position: center center;
				}
			}
		}
	}


	// PC style
	@media print, screen and (min-width: $breakpoint1){
		
		.Footer__contact {
			background: #CA0010;
			padding: 8px 0 100px;
			text-align: center;
			figure {
				text-align: left;
				img {
				}
			}
		}

		.Footer__contactInner {
			position: relative;
			width: 800px;
			margin: 0 auto;
		}

		.Footer__contactLinks {
			position: absolute;
			bottom: 4px;
			right: 50%;
			transform: translateX(50%);
			width: 792px;
			background: #262626;

			display: flex;
		}

		.Footer__contactLink {
			&.-tel {
				width: 47%;
				a {
					position: relative;
					padding: 19px 27px;
				}

				.Footer__contacText {
					position: absolute;
					top: 50%;
					left: 81px;
					transform: translateY(-50%);
					color: #fff;
					font-size: 40px;
					@include english(bold);
					letter-spacing: 0;
				}
				img {
				}
			}

			&.-contact {
				width: 53%;
				padding: 19px 19px;
				box-sizing: border-box;
				figure {
					text-align: center;
				}
				img {
					width: auto
				}

				.Footer__contacText {
					margin-top: 17px;
				}
			}

			a {
				display: block;
			}

		}


		.Footer__row {
			padding: 49px 0;
		}

		.Footer__siteName {
			font-size: 13px;
		}

		.Footer__copyright {
			font-size: 12px;
			margin-top: 8px;
		}
	}
}