/* --------------------
 * List
 * -------------------- */
.List {

	.List__ihead {
		display: flex;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		background: #CA0010;
		color: #fff;
		align-items: center;
		justify-content: center;
		font-size: 13px;
		font-weight: bold;
		margin-right: 10px;
		flex: 0 0 auto;

		&.-black{
			background: #000;
		}
	}

	.List__item {
		font-size: 12px;
		padding: 11px 15px;
		@include fontMedium;
		display: flex;
		align-items: center;
		background: #FAFAF5;
		
		&:not(:first-child) {
			margin-top: 3px;
		}
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){

		.List__ihead {
			width: 40px;
			height: 40px;
			font-size: 21px;
			margin-right: 20px;
		}

		.List__item {
			font-size: 16px;
			padding: 11px 15px;
			
			&:not(:first-child) {
				margin-top: 3px;
			}
		}
	}
}