/* --------------------
 * Colx3e
 * -------------------- */
.Colx3e {
	//some style
	
	.Colx3e__cols {
		display: flex;
		flex-wrap: wrap;
		@media print, screen and (max-width: $breakpoint1 - 1){
			justify-content: space-between;
		}
	}

	.Colx3e__col {
		width: calc((100% - 11px) / 2);
		box-sizing: border-box;
		@media print, screen and (max-width: $breakpoint1 - 1){
			&:nth-child(n+3) {
				margin-top: 15px;
			}
		}
	}

	.Colx3e__image {
		font-size: 0;
		img {
			width: 100%;
		}
	}

	.Colx3e__intro {
		font-size: 12px;
		margin-top: 18px;
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		.Colx3e__cols {
			padding: 0 19px;
		}

		.Colx3e__col {
			width: calc((100% - 100px) / 3);
			&:not(:first-child):not(:nth-child(3n+1)) {
				margin-left: 50px;
			}

			&:nth-child(n+4) {
				margin-top: 21px;
			}

		}

		.Colx3e__image {
		}

		.Colx3e__intro {
			font-size: 15px;
			margin-top: 27px;
		}

		&.-mt1 {
			margin-top: 20px;
		}
	}
}