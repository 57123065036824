/* --------------------
 * HeaderNav
 * -------------------- */
body.blog .HeaderNav__link.-blog,
body.faq .HeaderNav__link.-faq,
body.company .HeaderNav__link.-company,
body.item .HeaderNav__link.-item,
body.event .HeaderNav__link.-event,
body.price .HeaderNav__link.-price,
body.home .HeaderNav__link.-home {
	position: relative;
	&:before {
		content: '';
		bottom: 0;
		left: 0;
		position: absolute;
		width: 100%;
		height: 5px;
		background: #CA0010;
	} 
}

.HeaderNav {
	//some style
	.HeaderNav__links {
	}

	.HeaderNav__link {
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		border-bottom: 2px solid #E6E6E6;

		.HeaderNav__links {
			display: flex;
			justify-content: center;
		}

		.HeaderNav__link {
			display: flex;
			align-items: center;
			border-right: 1px solid #E6E6E6; 
			&:first-child {
				border-left: 1px solid #E6E6E6; 
			}
			width: 14.28%;
			max-width: 178px;
			justify-content: center;
			a {
				display: flex;
				align-items: center;
				text-align: center;
				height: 100%;
				padding: 6px 0;
				font-size: 15px;
				font-weight: bold;
				color: #262626;
				text-decoration: none;
				box-sizing: border-box;
				width: 100%;
				justify-content: center;
			}
		}
	}
}