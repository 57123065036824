/* --------------------
 * Colx3
 * -------------------- */
.Colx3 {
	//some style
	
	.Colx3__col {
		a {
			@media print, screen and (max-width: $breakpoint1 - 1){
				display: flex;
				justify-content: space-between;
			}
			padding: 10px 9px 22px;
			text-decoration: none;
			color: #262626;
		}
		border: 1px solid #C3C3C3;
		border-bottom: 5px solid #CA0010;
		
		@media print, screen and (max-width: $breakpoint1 - 1){
			&:not(:first-child) {
				margin-top: 16px;
			}
		}
	}

	&.-pgprice .Colx3__col {
		padding: 10px 9px 22px;
		border-bottom: 1px solid #C3C3C3;
		border-top: 5px solid #CA0010;
		box-sizing: border-box;
		@media print, screen and (max-width: $breakpoint1 - 1){
			display: flex;
			justify-content: space-between;
		}
	}
	
	.Colx3__colLowItem {
		font-size: 12px;
		&:not(.-last) {
			border-bottom: 1px dashed #C3C3C3;
			padding-bottom: 4px;
		}
		&:not(.-fst) {
			margin-top: 7px;
		}
	}

	.Colx3__colLowItemName {
		&:not(.-scene) {
			width: 6.6em;
		}

		&:not(.-scene).-w66 {
			width: 6.6em;
		}

		&.-scene {
			margin-bottom: 7px;
		}
		
		display: inline-block;
		font-weight: bold;
	}

	&.-pgprice .Colx3__colLowItemName {
		&:not(.-scene) {
			width: 8.1em;
		}
	}


	.Colx3__image {
		line-height: 1.0;
		@media print, screen and (max-width: $breakpoint1 - 1){
			width: calc( (100% - 15px) * 0.27);
		}
		img {
			width: 100%;
		}
	}

	.Colx3__colLow {
		@media print, screen and (max-width: $breakpoint1 - 1){
			width: calc( (100% - 15px) * 0.73);
		}
	}

	&.-mt1 {
		margin-top: 20px;
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.Colx3__col {
			width: calc((100% - 30px) / 3);
			a {
				display: block;
				padding: 9px 7px 16px;
				box-sizing: border-box;
			}
			&:nth-child(n+4) {
				margin-top: 23px;
			}
		}
		
		&.-pgprice .Colx3__col {
			padding: 9px 7px 16px;
			width: calc((100% - 22px) / 3);
		}

		&.-colx2 .Colx3__col {
			width: calc((100% - 22px) / 2);
			&:nth-child(n+3) {
				margin-top: 23px;
			}
		}

		.Colx3__image {
		}

		&.-pgprice .Colx3__colLow {
			padding: 0;
		}

		.Colx3__colLowItem {
			&:not(.-last) {
				padding-bottom: 11px;
			}
			&:not(.-fst) {
				margin-top: 13px;
			}
		}

		&.-pgprice .Colx3__colLowItem {
			font-size: 13px;
		}

		.Colx3__colLowItemName {
			&:not(.-scene) {
				width: 6.4em;
			}
			
			&.-scene {
				margin-bottom: 10px;
			}
		}
		
		&.-pgprice .Colx3__colLowItemName {
			width: 6.8em;
			font-size: 11px;
		}


		.Colx3__image {
			img {
			}
		}

		.Colx3__colLow {
			margin-top: 11px;
			padding: 0 6px;
		}
	}

	&.-mt1 {
		margin-top: 30px;
	}
}