/* --------------------
 * Colx3b
 * -------------------- */
.Colx3b {
	@media print, screen and (max-width: $breakpoint1 - 1){
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	
	.Colx3b__col {
		@media print, screen and (max-width: $breakpoint1 - 1){
			width:  calc((100% - 11px) / 2);
		}
		padding-bottom: 22px;
		color: #262626;
		
		@media print, screen and (max-width: $breakpoint1 - 1){
			&:nth-child(n+3) {
				margin-top: 16px;
			}
		}
	}

	.Colx3b__imageBox {
		position: relative;
		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;

			width: 0;
			height: 0;
			border-style: solid;
			border-width: 48px 48px 0 0;
			border-color: #CA0010 transparent transparent transparent;
		}
	}

	&.-noimgtag .Colx3b__imageBox {
		&:before {
			content: none;
		}
	}
	
	.Colx3b__imageText {
		position: absolute;
		top: 2px;
		left: 9px;
		color: #fff;
		font-size: 15px;
		font-weight: bold;
	}
	
	.Colx3b__colLowItem {
		font-size: 12px;
		&:not(.-fst) {
			margin-top: 7px;
		}
	}

	&.-pgitem .Colx3b__colLowItem {
		font-size: 16px;
		@include fontMedium;
		text-align: center;
	}

	.Colx3b__colLowItemName {
		font-weight: bold;
		font-size: 15px;
		letter-spacing: 0;
	}

	.Colx3b__image {
		font-size: 0;
		img {
			width: 100%;
		}
	}

	.Colx3b__colLow {
	}

	&.-mt1 {
		margin-top: 20px;
	}

	&.-mt27 {
		margin-top: 27px;
	}

	&.-mt38 {
		margin-top: 38px;
	}

	&.-mt41 {
		margin-top: 41px;
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		display: flex;
		flex-wrap: wrap;

		.Colx3b__col {
			width: calc((100% - 81px) / 3);
			display: block;
			padding: 0 0 16px;
			box-sizing: border-box;
			&:nth-child(n+4) {
				margin-top: 23px;
			}

			&:not(:first-child):not(:nth-child(3n+1)) {
				margin-left: 40px;
			}
		}

		&.-wclac36 .Colx3b__col {
			width: calc((100% - 36px) / 3);
			&:not(:first-child):not(:nth-child(3n+1)) {
				margin-left: 18px;
			}
		}

		.Colx3b__imageBox {
			&:before {
				border-width: 69px 69px 0 0;
			}
		}
		
		.Colx3b__imageText {
			top: 4px;
			left: 13px;
			font-size: 21px;
		}

		.Colx3b__image {
		}

		.Colx3b__colLowItem {
			&:not(.-fst) {
				margin-top: 13px;
			}
		}

		.Colx3b__colLowItemName {
			font-size: 20px;
		}

		.Colx3b__image {
			img {
			}
		}

		.Colx3b__colLow {
			margin-top: 8px;
		}
	}

	&.-mt1 {
		margin-top: 30px;
	}
}