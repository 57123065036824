/* --------------------
 * Btn1
 * -------------------- */
.Btn1 {
	
	.Btn1__icon {
		width: 37px;
		img {
			width: 100%;
		}
		margin-right: 13px
	}

	button,
	a {
		font-size: 14px;
		color: #fff;
		font-weight: bold;
		padding: 18px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-decoration: none;
		background: #ED8900;
		border-radius: 36px;
		box-shadow:4px 4px 0px 0px rgba(0, 0, 0, 0.1);

		position: relative;
		&:before {
			position: absolute;
			top: 50%;
			right: 0;
			transform: translate(-300%, -50%) rotate(45deg);
			content: '';
			width: 7px;
			height: 7px;
			border-top: 2px solid #fff;
			border-right: 2px solid #fff;
		}
	}

	button {
		width: 100%;
		border: none;
	}

	&.-noarrow a:before {
		content: none;
	}

	&.-red button,
	&.-red a {
		background: #CA0010;
	}

	&.-black a {
		background: #262626;
	}

	&.-mt1 {
		margin-top: 20px;
	}

	&.-mt33 {
		margin-top: 33px;
	}

	&.-s {
		a {
			font-size: 15px;
			padding: 19px;
			box-shadow:2px 2px 0px 0px rgba(0, 0, 0, 0.1);
			letter-spacing: -0.01em;

			&:before {
				width: 7px;
				height: 7px;
				border-top: 2px solid #fff;
				border-right: 2px solid #fff;
				transform: translate(-230%, -50%) rotate(45deg);
			}
		}
	}

	&.-ss {
		a {
			font-size: 14px;
			padding: 8px;
			box-shadow:2px 2px 0px 0px rgba(0, 0, 0, 0.1);
			letter-spacing: -0.01em;

			&:before {
				width: 7px;
				height: 7px;
				border-top: 2px solid #fff;
				border-right: 2px solid #fff;
				transform: translate(-190%, -50%) rotate(45deg);
			}
		}
	}



	&.-noshadow {
		button,
		a {
			box-shadow: none;
		}
	}

	&.-w130 {
		width: 130px;
	}

	&.-w195 {
		width: 195px;
	}

	&.-w222 {
		width: 222px;
	}

	&.-pd7 a {
		padding: 7px;
	}

	&.-pd9 {
		button,
		a {
			padding: 9px;
		}
	}

	&.-pd11 a {
		padding: 11px;
	}

	&.-pd13 a {
		padding: 13px;
	}

	&.-a1 a:before {
		width: 7px;
		height: 7px;
		border-top: 2px solid #fff;
		border-right: 2px solid #fff;
		transform: translate(-190%, -50%) rotate(45deg);
	}

	&.-a2 a:before {
		width: 6px;
		height: 6px;
		border-top: 2px solid #fff;
		border-right: 2px solid #fff;
		transform: translate(-190%, -50%) rotate(45deg);
	}

	&.-fs12 a {
		font-size: 12px;
	}

	&.-fs14 a {
		font-size: 14px;
	}

	&.-fs17 a {
		font-size: 17px;
	}

	&.-lrauto {
		margin-left: auto;
		margin-right: auto;
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		&.-w280 {
			width: 280px;
		}

		&.-w510 {
			width: 510px;
		}

		a {
			font-size: 20px;

			position: relative;
			&:before {
				transform: translate(-300%, -50%) rotate(45deg);
				width: 12px;
				height: 12px;
				border-top: 3px solid #fff;
				border-right: 3px solid #fff;
			}
		}

		&.-mt1 {
			margin-top: 51px;
		}


		&.-pd15 {
			a {
				padding: 15px;
			}
		}

		&.-fs18 {
			a {
				font-size: 18px;
			}
		}

		&.-tr160 {
			a:before {
				transform: translate(-160%, -50%) rotate(45deg);
			}
		}

		&.-green {
			a {
				background: #69b80e;
			}
		}
	}
}