.Breadcrumb{
	//some style
	background: #FAFAF5;
	
	@media print, screen and (max-width: $breakpoint1 - 1){
		margin-left: -20px;
		margin-right: -20px;
		padding: 9px 20px;
	}

	.Breadcrumb__link {
		display: flex;
		@media print, screen and (max-width: $breakpoint1 - 1){
			&:not(:first-child) {
				margin-left: 26px;
			}
		}
	}

	.Breadcrumb__sign {
    margin-right: 5px;
    margin-left: 7px
	}

	.Breadcrumb__icon {
		width: 16px;
		margin-right: 10px;
	}

	a {
		display: flex;
		align-items: center;
		text-decoration: none;
		color: #1A1A1A;
		font-size: 10px;
	}
	
	// PC style
	@media print, screen and (min-width: $breakpoint1){
		padding: 16px 17px;
		display: flex;

		.Breadcrumb__icon {
			width: 18px;
		}

		a {
			font-size: 12px;
		}
	}
}