/* --------------------
 * Asideright
 * -------------------- */
.Asideright {
	//some style
	.Asideright__image {
		font-size: 0;
		img {
			width: 100%;
		}
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		.Asideright__image {
			&:not(.-fst) {
				margin-top: 12px;
			}
		}
	}
}