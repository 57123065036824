/* --------------------
 * Colx1c
 * -------------------- */
.Colx1c {

	&.-mt18 {
		margin-top: 18px;
	}

	&.-mt31 {
		margin-top: 31px;
	}

	.Colx1c__col {
		font-size: 15px;
		padding: 10px 9px 22px;
		&:not(:first-child) {
			margin-top: 21px;
		}
		border: 2px solid #C3C3C3;
	}

	.Colx1c__colbox {
		display: flex;
		@media print, screen and (max-width: $breakpoint1 - 1){
			flex-direction: column-reverse;
		}
	}

	.Colx1c__left {
		@media print, screen and (max-width: $breakpoint1 - 1){
			margin-top: 1em;
		}
	}

	.Colx1c__titleBox {
	}

	.Colx1c__title {
		font-size: 15px;
		font-weight: bold;
		color: #CA0010;
	}

	.Colx1c__intro {
		font-size: 12px;
		margin-top: 12px;
		letter-spacing: 0;
		line-height: 1.9;
	}

	.Colx1c__right {
		@media print, screen and (max-width: $breakpoint1 - 1){
		}
	}

	.Colx1c__image {
		font-size: 0;
	}


	.Colx1c__item {
		display: flex;
		border-top: 1px solid #C3C3C3;
		border-bottom: 1px solid #C3C3C3;
		margin-top: 2em;
	}

	.Colx1c__itemName {
		background: #F9F9F9;
		font-size: 12px;
		font-weight: bold;
		padding: 12px 25px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 0 0 auto;
	}

	.Colx1c__itemVal {
		font-size: 12px;
		padding: 12px 14px;
	}

	.Colx1c__btn {
		margin-top: 12px;
	}

	.Colx1c__content {
		&.Movielink {
			margin-top: 12px;
		}
	}

	
	// PC style
	@media print, screen and (min-width: $breakpoint1){
		.Colx1c__col {
			font-size: 15px;
			padding: 24px 28px;
			&:not(:first-child) {
				margin-top: 21px;
			}
		}

		.Colx1c__colbox {
			justify-content: space-between;
		}

		.Colx1c__left {
			width: calc( (100% - 24px) * 0.5);
		}

		.Colx1c__titleBox {
		}

		.Colx1c__title {
			font-size: 22px;
		}

		.Colx1c__intro {
			font-size: 14px;
			margin-top: 21px;
		}

		.Colx1c__right {
			width: calc( (100% - 24px) * 0.5);
		}

		.Colx1c__image {
			img {
				width: 100%;
			}
		}

		.Colx1c__item {
			margin-top: 104px;
		}

		.Colx1c__itemName {
			font-size: 12px;
			padding: 12px 25px;
		}

		.Colx1c__itemVal {
			font-size: 12px;
			padding: 12px 14px;
		}

		.Colx1c__btn {
			margin-top: 12px;
		}

		.Colx1c__content {
			&.Movielink {
				margin-top: 23px;
			}
		}

	}
}