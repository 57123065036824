/* --------------------
 * Present
 * -------------------- */
.Present {
	background: rgba(255, 241, 0, 0.2);

		padding: 65px 15px 15px 30px;
		position: relative;
		
		.Presen__title {
			color: #fff;
			background-image: url(../images/guide/present_bg.png);
			background-size: contain;
			background-position: center center;
			background-repeat: no-repeat;
			width: 224px;
			height: 74px;
			position: absolute;
			top: -23px;
			left: -5px;
		}

		.Present__introBox {

		}

		.Presen__intro {
			&.-i1 {
				font-size: 12px;
				color: #262626;
				letter-spacing: 0;
			}

			&.-i2 {
				font-size: 15px;
				color: #E36000;
				font-weight: bold;
				margin-top: 15px;
				margin-left: -1em;
			}

			&.-i3 {
				font-size: 13px;
				color: #262626;
				font-weight: bold;
				margin-top: 10px;
			}

		}

		.Presen__image {
			width: 162px;
			font-size: 0;
			margin: 15px auto 0;
			img {
				width: 100%;
			}
		}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		padding: 74px 22px 22px 48px;
		display: flex;
		// justify-content: space-between;
		justify-content: center;
		position: relative;
		
		.Presen__title {
			color: #fff;
			width: 320px;
			height: 105px;
			top: -30px;
			left: -10px;
		}

		.Present__introBox {

		}

		.Presen__intro {
			&.-i1 {
				font-size: 16px;
			}

			&.-i2 {
				font-size: 24px;
				margin-top: 31px;
				margin-left: -13px;
			}

			&.-i3 {
				font-size: 20px;
				margin-top: 10px;
			}

		}

		.Presen__image {
			width: 223px;
			img {
				width: 100%;
			}
		}

	}
}