/* --------------------
 * Movielink
 * -------------------- */
.Movielink {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: rgba(237, 137, 0, 0.15);

	@media print, screen and (max-width: $breakpoint1 - 1){
		padding: 12px;

		flex-wrap: wrap;
		justify-content :center;

		.Movielink__image {
			width: calc( (100% - 15px) * 0.3);
		}

		.Movielink__text {
			width: calc( (100% - 15px) * 0.7);
			margin-left: 15px;
		}

		.Movielink__btn {
			margin-top: 12px;
			width: 130px;
			a {
				padding: 7px;
				font-size: 12px;
			}
		}
	}

	.Movielink__image {
		font-size: 0;
	}

	.Movielink__text {
		font-size: 12px;
		color: #262626;
		font-weight: bold;
	}

	.Movielink__font {
		font-size: 18px;
		font-weight: bold;
		color: #E36000;
		&.-mindent{
			margin-left: -0.5em;
		}
	}

	.Movielink__btn {

	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		padding: 20px 26px 16px 160px;
		
		position: relative;

		.Movielink__text {
			font-size: 13px;
			color: #262626;
			font-weight: bold;
		}

		.Movielink__image {
			position: absolute;
			top: 2px;
			left: -2px;
			width: 141px;
			img {
				width: 100%;
			}
		}

		.Movielink__text {

		}

	.Movielink__font {
		font-size: 26px;
	}


		.Movielink__btn {

		}
	}
}