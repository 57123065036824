/* --------------------
 * Section
 * -------------------- */
.Section {
	//some style
	&.-nav {
		margin-top: 21px;
	}

	&.-event {
		margin-top: 36px;
	}

	&.-result {
		margin-top: 36px;
	}

	&.-product {
		margin-top: 36px;
	}

	&.-exps {
		margin-top: 40px;
	}

	// price
	&.-service {
		margin-top: 5px;
	}

	&.-deal {
		margin-top: 30px;
	}

	&.-reason {
		margin-top: 26px;
	}

	&.-flow {
		margin-top: 30px;
	}

	&.-other {
		margin-top: 28px;
	}

	&.-eventexps {
		margin-top: 28px;
	}

	&.-price {
		margin-top: 31px;
	}

	// item
	&.-item {
		margin-top: 5px
	}

	&.-outdoor {
		margin-top: 25px;
	}

	&.-indoor {
		margin-top: 40px;
	}

	// guide
	&.-point {
		margin-top: 25px;

		.Section__item {
			&.Colx3c {
				margin-top: 13px;
			}

			&.Qa {
				margin-top: 13px;
			}

			&.Colx3d {
				margin-top: 13px;
			}
		}
	}

	&.-eitem {
		margin-top: 30px;

		.Section__item {
			&.Colx3d {
				margin-top: 15px;
			}
		}
	}

	&.-citem {
		margin-top: 25px;

		.Section__item {
			&.Colx3d {
				margin-top: 15px;
			}
		}
	}

	&.-litem {
		margin-top: 25px;

		.Section__item {
			&.Colx3d {
				margin-top: 15px;
			}
		}
	}

	&.-ritem {
		margin-top: 25px;

		.Section__item {
			&.Colx3d {
				margin-top: 15px;
			}
		}
	}

	&.-eother {
		margin-top: 25px;

		.Section__item {
			&.Colx3e {
				margin-top: 15px;
			}

			&.Btn1 {
				margin-top: 25px;
			}
		}
	}

	&.-present {
		margin-top: 44px;
	}
	
	// company
	&.-company {
		margin-top: 30px;

		.Section__item {
			&.List {
				margin-top: 14px;
			}

			&.Btn1 {
				margin-top: 20px;
			}
		}
	}

	&.-support {
		margin-top: 30px;
		.Section__item {
			&.Subtitle {
				margin-top: 16px;
				&.-icenter {
					margin-top: 30px;
				}
			}

			&.Colx1d {
				margin-top: 10px;
			}
		}
	}

	&.-photog {
		margin-top: 30px;

		.Section__item {
			&.Colx3b {
				margin-top: 15px;
			}
		}
	}

	&.-permission {
		margin-top: 30px;

		.Section__item {
			&.Subtitle {
				margin-top: 15px;
			}

			&.Colx2b {
				margin-top: 14px;
			}

			&.-certified {
				margin-top: 25px;
			}

			&.Colx3f {
				margin-top: 15px;
			}

			&.Btn1 {
				margin-top: 26px;
			}
		}

		.Section__image {
			margin: 40px auto 0;
			img {
				width: 100%;
			}
		}
	}

	// event
	&.-aboutexps {
		margin-top: 5px;
	}

	&.-scene {
		margin-top: 30px;
		.Link {
			margin-top: 28px;
		}
	}

	&.-new {
		margin-top: 60px;

		&.-ekind {
			margin-top: 30px;
		}

		.Section__item {
			&.Colx3 {
				margin-top: 30px;
			}
		}
	}

	// single page /event/.../
	&.-aboutexps {
		.Section__item {
			&.Colx6 {
				margin-top: 8px;
			}

			&.Table {
				margin-top: 20px;
			}

			&.-comment {
				margin-top: 20px;
			}

			&.Paginateb {
				margin-top: 40px;
			}
		}
	}

	// toranomaki
	&.-toranomaki {
		margin-top: 15px;

		.Section__intro {
			&.-i1 {
				font-size: 13px;
				margin-top: 16px;
				line-height: 1.8;
			}

			&.-i2 {
				margin-top: 12px;
				padding: 10px 13px;
				font-size: 16px;
				@include fontMedium;
				color: #E36000;
				background: rgba(255, 241, 0, 0.3); 
			}

			&.-i3 {
				font-size: 13px;
				margin-top: 12px;
			}

		}
	}

	&.-form {
		margin-top: 28px;

		.Section__intro {
			margin-top: 18px;
		}

		.Section__item {
			&.Form {
				margin-top: 12px;
			}
		}
	}

	// toranomaki/thanks
	&.-tothanks {
		margin-top: 30px;

		.Section__intro {
			&.-i1 {
			}

			&.-i2 {
				font-size: 15px;
				font-weight: bold;
			}

			&.-i3 {

			}

		}
	}

	// contact
	&.-contact {
		margin-top: 15px;
	}

	&.-tel {
		margin-top: 28px;

		.Section__item {
			&.-tel {
				margin-top: 11px;
				position: relative;

				.Section__image {
					font-size: 0;
					margin-top: 0;
					img {
						width: 222px;
					}
				}

				.Section__text {
					a {
						font-size: 27px;
						@include english(bold);
						color: #CA0010;

						position: absolute;
						top: 0;
						left: 50px;
						letter-spacing: -0.01em;
						text-decoration: none;
						line-height: 1.4;
					}
				}
			}
		}
	}

	&.-cform {
		.Section__item {
			&.Form {
				margin-top: 12px;
			}
		}
	}

	// contact/thanks
	&.-cthanks {
		margin-top: 30px;

		.Section__intro {
			&.-i1 {
			}

			&.-i2 {
				font-size: 15px;
				font-weight: bold;
			}

			&.-i3 {

			}

		}
	}
	
	// faq
	&.-faq {
		margin-top: 10px;

		.Section__item {
			&.Faq {
				margin-top: 26px;
			}
		}
	}
	
	// blog
	&.-blog {
		margin-top: 30px;

		.Section__item {
			&.Colx2c {
				margin-top: 30px;
			}
		}
	}
	
	// privacy
	&.-privacy{
		margin-top: 15px;
		.Section__item {
			&.-item1 {
				border-bottom: 1px solid #C3C3C3;
				padding-bottom: 15px;
				margin-bottom: 36px;
			}
			&.-item2 {
				
			}
		}
		.Section__intro{
			&.-head{
				// font-size: 15px;
				font-weight: bold;
			}
		}
	}


	.Section__intro {
		margin-top: 13px;
		font-size: 12px;
	}

	.Section__text {
		font-size: 17px;
		text-align: center;
		color: #262626;
		font-weight: bold;
		margin-top: 25px;
	}

	.Section__images {
		margin-top: 18px;
		display: flex;
		justify-content: center;
		a {
			border: 2px solid #ccc;
			display: block;
			width: 100px;
			font-size: 0;
			img {
				width: 100%;
			}

			&:last-child {
				margin-left: 35px;
			}
		}
	}
	
	.Section__image {
		font-size: 0;
		margin-top: 28px;
	}

	.Section__paginate {
		margin-top: 20px;
	}

	.Section__item.-comment {
		padding: 9px 11px;
		border-radius: 10px;
		border: 2px solid #C3C3C3;

		.Section__itemtitle {
			font-size: 13px;
			font-weight: bold;
			display: flex;
			align-items: center;
		}

		.Section__itemdesc p {
			font-size: 12px;
			margin-top: 6px;
			line-height: 2.0;
		}

		img {
			width: 25px;
			margin-right: 5px;
		}
	}


	// PC style
	@media print, screen and (min-width: $breakpoint1){
		&.-nav {
			margin-top: 11px;
		}

		&.-event {
			margin-top: 50px;
		}

		&.-result {
			margin-top: 50px;
		}

		&.-product {
			margin-top: 36px;
		}

		&.-exps {
			margin-top: 62px;
		}

		// price
		&.-service {
			margin-top: 9px;
		}

		&.-deal {
			margin-top: 61px;
		}

		&.-reason {
			margin-top: 52px;
		}

		&.-flow {
			margin-top: 60px;
		}

		&.-other {
			margin-top: 36px;
		}

		&.-eventexps {
			margin-top: 57px;
		}

		&.-price {
			margin-top: 62px;
		}

		// item
		&.-item {
			margin-top: 9px;
		}

		&.-outdoor {
			margin-top: 50px;
		}

		&.-indoor {
			margin-top: 80px;
		}

		// guide
		&.-point {
			margin-top: 50px;

			.Section__item {
				&.Colx3c {
					margin-top: 26px;
				}

				&.Qa {
					margin-top: 26px;
				}

				&.Colx3d {
					margin-top: 26px;
				}
			}
		}

		&.-eitem {
			margin-top: 61px;

			.Section__item {
				&.Colx3d {
					margin-top: 30px;
				}
			}
		}

		&.-citem {
			margin-top: 50px;

			.Section__item {
				&.Colx3d {
					margin-top: 30px;
				}
			}
		}

		&.-litem {
			margin-top: 50px;

			.Section__item {
				&.Colx3d {
					margin-top: 30px;
				}
			}
		}

		&.-ritem {
			margin-top: 50px;

			.Section__item {
				&.Colx3d {
					margin-top: 30px;
				}
			}
		}

		&.-eother {
			margin-top: 50px;

			.Section__item {
				&.Colx3e {
					margin-top: 30px;
				}

				&.Btn1 {
					margin-top: 51px;
				}
			}
		}

		&.-present {
			margin-top: 87px;
		}

		// company
		&.-company {
			margin-top: 61px;

			.Section__item {
				&.List {
					margin-top: 27px;
				}

				&.Btn1 {
					margin-top: 41px;
				}
			}
		}

		&.-support {
			margin-top: 61px;
			.Section__item {
				&.Subtitle {
					margin-top: 32px;
					&.-icenter {
						margin-top: 59px;
					}
				}

				&.Colx1d {
					margin-top: 20px;
				}
			}
		}

		&.-photog {
			margin-top: 60px;

			.Section__item {
				&.Colx3b {
					margin-top: 30px;
				}
			}
		}

		&.-permission {
			margin-top: 60px;

			.Section__item {
				&.Subtitle {
					margin-top: 30px;
				}

				&.Colx2b {
					margin-top: 28px;
				}

				&.-certified {
					margin-top: 50px;
				}

				&.Colx3f {
					margin-top: 31px;
				}

				&.Btn1 {
					margin-top: 52px;
				}
			}

			.Section__image {
				margin: 80px auto 0;
				width: 760px;
				img {
					width: 100%;
				}
			}
		}

		// event
		&.-aboutexps {
			margin-top: 9px;
		}

		&.-scene {
			margin-top: 30px;
			.Link {
				margin-top: 28px;
			}
		}

		&.-new {
			margin-top: 60px;

			&.-ekind {
				margin-top: 30px;
			}

			.Section__item {
				&.Colx3 {
					margin-top: 30px;
				}
			}
		}

		// single page /event/.../
		&.-aboutexps {
			.Section__item {
				&.Colx6 {
					margin-top: 15px;
				}

				&.Table {
					margin-top: 40px;
				}

				&.-comment {
					margin-top: 40px;
				}

				&.Paginateb {
					margin-top: 80px;
				}
			}
		}

		// toranomaki
		&.-toranomaki {
			margin-top: 30px;

			.Section__intro {
				&.-i1 {
					font-size: 16px;
					margin-top: 32px;
				}

				&.-i2 {
					margin-top: 12px;
					padding: 18px 25px;
					font-size: 21px;
				}

				&.-i3 {
					font-size: 16px;
					margin-top: 12px;
				}

			}
		}

		// toranomaki/thanks
		&.-tothanks {
			margin-top: 30px;

			.Section__intro {
				&.-i1 {
				}

				&.-i2 {
					font-size: 24px;
				}

				&.-i3 {

				}

			}
		}

		&.-form {
			margin-top: 53px;

			.Section__intro {
				margin-top: 37px;
			}

			.Section__item {
				&.Form {
					margin-top: 21px;
				}
			}
		}

		// contact
		&.-contact {
			margin-top: 15px;
		}

		&.-tel {
			margin-top: 28px;

			.Section__intro {
				margin-top: 18px;
			}

			.Section__item {
				&.-tel {
					margin-top: 11px;

					.Section__image {
						img {
							width: 353px;
						}
					}

					.Section__text {
						a {
							font-size: 43px;

							position: absolute;
							top: 0;
							left: 76px;
						}
					}
				}
			}
		}

		&.-cform {
			.Section__item {
				&.Form {
					margin-top: 12px;
				}
			}
		}
		
		// contact/thanks
		&.-cthanks {
			margin-top: 30px;

			.Section__intro {
				&.-i1 {
				}

				&.-i2 {
					font-size: 24px;
				}

				&.-i3 {

				}

			}
		}

		// faq
		&.-faq {
			margin-top: 10px;

			.Section__item {
				&.Faq {
					margin-top: 52px;
				}
			}
		}

		// blog
		&.-blog {
			margin-top: 30px;

			.Section__item {
				&.Colx2c {
					margin-top: 30px;
				}
			}
		}
		// privacy
		&.-privacy{
			margin-top: 30px;
			.Section__item {
				&.-item1 {
					padding-bottom: 25px;
				}
				&.-item2 {
					
				}
			}
			.Section__intro{
				&.-head{
					// font-size: 24px;
				}
			}
		}


		.Section__intro {
			font-size: 15px;
			margin-top: 25px;
		}

		.Section__text {
			font-size: 28px;
			margin-top: 50px;
		}

		.Section__text.-pgitem {
			font-size: 21px;
			margin-top: 32px;
		}

		.Section__images {
			a {
				&:last-child {
					margin-left: 70px;
				}
			}
		}

		.Section__image {
			margin-top: 55px;
			&.-bnrpresent img {
				width: 100%;
			}
		}

		.Section__content {
			&.-pgitem {
				margin-top: 18px;
			}
		}

		.Section__paginate {
			margin-top: 40px;
		}

		.Section__item.-comment {
			padding: 18px 22px;

			.Section__itemtitle {
				font-size: 18px;
			}

			.Section__itemdesc p {
				font-size: 14px;
				margin-top: 12px;
			}

			img {
				width: 32px;
				margin-right: 10px;
			}
		}
	}
}