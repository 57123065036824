@charset "UTF-8";

// @import '../../sass/vender/_normalize.scss';
@import '../../sass/vender/_reset.scss';
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/lib/_mixin.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/lib/_vars.scss";



// webfont
// Google Roboto
@import url('//fonts.googleapis.com/css?family=Roboto:300,400,500,700');
// Google Loto
// @import url('//fonts.googleapis.com/css?family=Lato:100,300,400,700,900');
// Google Noto Fonts
@import url('//fonts.googleapis.com/earlyaccess/notosansjp.css');
// font-weight: 100; => Noto Sans JP Thin
// font-weight: 300; => Noto Sans JP Light
// font-weight: 400; => Noto Sans JP Regular
// font-weight: 500; => Noto Sans JP Medium
// font-weight: 600; => Noto Sans JP Bold
// font-weight: 800; => Noto Sans JP Black




/* font setting */
// Yu Gothic
// @import '../../sass/font/_yugothic.scss';
// icon font / icomoon
// @import '../../sass/font/_icon.scss';




/* default setting */
body{
	background-color: #ffffff;
	color: #222;
	@include fontRegular();
	// 	font-family: 'Noto Sans JP', 'ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','メイリオ',Meiryo,'ＭＳ Ｐゴシック',sans-serif;
	// 	font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", sans-serif;
	font-size: 14px;
	line-height: 1.6;
	letter-spacing: 0.05em;
	-webkit-text-size-adjust: 100%;
}

@media print, screen and (min-width: $breakpoint1){
	a,
	button {
		&:hover{
			text-decoration: none;
			opacity: 0.6;
			transition: opacity 200ms;
		}

		cursor: pointer;
	}
}

a{
	color: rgb(20, 141, 240);
	text-decoration: underline;
}

ul{
	list-style:none;
}


// SP style
@media print, screen and (max-width: $breakpoint1 - 1){
	img{
		width: 100%;
		height: auto;
	}
	.pc{
		display: none !important;
	}
}
// PC style
@media print, screen and (min-width: $breakpoint1){
	.sp{
		display: none !important;
	}

	#root {
		min-width: 1280px;
	}

}



/* components */
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_Asideleft.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_Asideright.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_Blog.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_Bmenu.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_Breadcrumb.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_Btn1.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_Colx1.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_Colx1b.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_Colx1c.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_Colx1d.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_Colx1e.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_Colx2.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_Colx2b.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_Colx2c.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_Colx3.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_Colx3b.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_Colx3c.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_Colx3d.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_Colx3e.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_Colx3f.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_Colx4.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_Colx6.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_Contentsarea.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_Faq.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_footer.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_FooterNav.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_Form.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_Header.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_HeaderNav.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_ItemDetail.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_Link.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_List.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_Main.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_Mainav.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_Mainv.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_Movielink.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_Pagetitle.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_Paginate.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_Paginateb.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_Paginatec.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_Point.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_Present.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_Qa.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_ResultSlider.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_Section.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_Subtitle.scss";
@import "C:/xampp/htdocs/events-kikaku190828/src/events-kikaku/sass/components/_Table.scss";

/* pages */

