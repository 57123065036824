/* --------------------
 * Point
 * -------------------- */
.Point {
	
	&.-mt34 {
		margin-top: 17px;
	}

	&.-mt109 {
		margin-top: 34px;
	}

	.Point__title {
		font-size: 16px;
		font-weight: bold;
		color: #E60012;
		background-image: url(../images/price/point_bg.png);
    background-repeat: no-repeat;
    background-position: top left;
    background-size: contain;
    line-height: 1.0;
    width: 177px;
    text-align: center;
    padding: 3px 0;
	}

	.Point__intro {
		font-size: 17px;
		font-weight:bold;
		color: #E36000;
		background: rgba(237, 137, 0, 0.1);
		border-radius: 6px;
		padding: 10px 13px;
		margin-top: 18px;
		letter-spacing: 0;
		
		position: relative;
	}

	.Point__font {
		font-size: 12px;
		color: #262626;
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		&.-mt34 {
			margin-top: 34px;
		}

		&.-mt109 {
			margin-top: 109px;
		}

		.Point__title {
	    width: 280px;
	    text-align: center;
			font-size: 26px;
			padding: 4px 0;
			margin-left: 19px
		}

		&.-imgleft .Point__title {
			margin-left: 248px
		}

		.Point__intro {
			font-size: 28px;
			padding: 21px 38px;
			line-height: 1.4;
			margin-top: 18px;
			&:after {
				display: block;
				position: absolute;
				bottom: 0;
				right: 0;
				content: '';
				background-image: url(../images/price/point_01.png);
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center bottom;
				width: 248px;
				height: 262px;
			}
		}

		&.-imgleft .Point__intro {
			padding: 21px 0px 21px 248px;
			&:after {
				left: 0;
				background-image: url(../images/price/point_02.png);
			}
		}

		.Point__font {
			font-size: 20px;
		}
	}
}