.Form{
	background: #F8F5ED;
	border: 1px solid #C3C3C3;
	padding: 24px 20px;

	*{
		font-size: 16px;
		@include fontRegular;
		&:focus{
			outline: none;
		}
	}

	.Form__item {
		&:not(.-company) {
			margin-top: 20px;
		}
	}

	.Form__itemTitle {
	}

	.Form__itemTitleText {
		display: flex;
		align-items: center;
		font-size: 15px;
		font-weight: bold;
	}

	.Form__itemDetail {
		margin-top: 4px;

		input[type=text] {
			padding: 0 13px;
			height: 30px;
		}

		textarea {
			height: 200px;
			padding: 5px 13px;
		}

		span.error {
			margin-top: 0.5em;
		}
		
	}

	textarea,
	input[type=text] {
		border: 1px solid #C3C3C3;
		background: #fff;
		width: 100%;
		box-sizing: border-box;
		&::placeholder  {
			color: #C3C3C3;
		}
	}

	.Form__submit {
		margin-top: 31px;
	}

	.Form__require {
		color: #CA0010;
		font-size: 13px;
		font-weight: bold;
	}

	@media only screen and (min-width: $breakpoint1){
		padding: 24px 40px;
		
		.Form__item {

		}

		.Form__itemTitle {

		}

		.Form__itemTitleText {
			font-size: 15px;
		}

		.Form__require {
			font-size: 13px;
		}

		.Form__itemDetail {
			font-size: 16px;
			margin-top: 8px;
			input[type=text] {
				padding: 0 13px;
				height: 37px;
			}
			textarea {
				padding: 5px 13px;
				height: 144px;
			}
		}

		.Form__submit {
			margin-top: 31px;
		}
	}
}
