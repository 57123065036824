/* --------------------
 * Colx4
 * -------------------- */
.Colx4 {
	//some style
	
	.Colx4__cols {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.Colx4__col {
		width: calc((100% - 11px) / 2);
		box-sizing: border-box;
		@media print, screen and (max-width: $breakpoint1 - 1){
			&:nth-child(n+3) {
				margin-top: 15px;
			}
		}
	}

	.Colx4__image {
		font-size: 0;
		img {
			width: 100%;
		}
	}

	.Colx4__intro {
		font-size: 12px;
		margin-top: 18px;
	}

	&.-mt1 {
		margin-top: 20px;
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		.Colx4__col {
			width: calc((100% - 39px) / 4);
			&:nth-child(n+5) {
				margin-top: 21px;
			}
		}

		.Colx4__image {
		}

		.Colx4__intro {
			font-size: 15px;
			margin-top: 27px;
		}

		&.-mt1 {
			margin-top: 20px;
		}
	}
}