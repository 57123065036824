// breakpoint
$breakpoint1: 768px;
// $breakpoint2: 960px;
//
// // size
// $pcMinWidth: 1000px + 50px + 50px;
// $pcMaxWidth: 1344px + 50px + 50px;
//
//
// // color
// $colorMain: #0b308e;
// $colorSub: #0099cc;
// $colorBg1: #fff;
// $colorBg2: #f2f2f2;
// $colorText1: #333333;
// $colorText2: #a6a6a6;
// $colorBorder1: #b5c0de;
//
//
// // icons
// $iconMap: (
// 	icon-mypage:      '\e900',
// 	icon-menu:        '\e901',
// 	icon-close:       '\e902',
// 	icon-right:       '\e903',
// 	icon-top:         '\e904',
// 	icon-plus:        '\e905',
// 	icon-blank:       '\e906',
// 	icon-blank-small: '\e907',
// 	icon-airplane:    '\e908',
// 	icon-calendar:    '\e909',
// 	icon-home2:       '\e90b',
// 	icon-home1:       '\e90a',
// 	icon-entry:       '\e90c',
// );
