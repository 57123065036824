/* --------------------
 * Colx2c
 * -------------------- */
.Colx2c {
	//some style
	
	.Colx2c__col {
		padding: 10px 9px 22px;
		box-sizing: border-box;
		border: 1px solid #C3C3C3;
		border-bottom: 5px solid #CA0010;

		a {
			text-decoration: none;
			color: #262626;
		}
		
		@media print, screen and (max-width: $breakpoint1 - 1){
			display: flex;
			justify-content: space-between;

			&:not(:first-child) {
				margin-top: 16px;
			}
		}
	}

	.Colx2c__colLowItem {
		font-size: 12px;
		&:not(.-fst) {
			margin-top: 7px;
		}

		&.-fst {
			font-weight: bold;
			border-bottom: 1px dashed #C3C3C3;
			padding-bottom: 4px;

		}

		&.-items {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}

	.Colx2c__colLowSubitem {
		&.-date {

		}

		&.-cat {
			font-size: 10px;
			background: #CA0010;
			padding: 3px 5px;
			a {
				color: #fff;
			}
		}
	}

	.Colx2c__image {
		line-height: 1.0;
		@media print, screen and (max-width: $breakpoint1 - 1){
			width: calc( (100% - 15px) * 0.27);
		}
		img {
			width: 100%;
		}
	}

	.Colx2c__colLow {
		@media print, screen and (max-width: $breakpoint1 - 1){
			width: calc( (100% - 15px) * 0.73);
		}
	}

	&.-mt1 {
		margin-top: 20px;
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.Colx2c__col {
			width: calc((100% - 22px) / 2);
			padding: 9px 7px 16px;
			a {
				display: block;
				box-sizing: border-box;
			}
			&:nth-child(n+3) {
				margin-top: 23px;
			}
		}

		.Colx2c__image {
		}

		&.-pgprice .Colx2c__colLow {
			padding: 0;
		}

		.Colx2c__colLowItem {
			&:not(.-last) {
				padding-bottom: 11px;
			}
			&:not(.-fst) {
				margin-top: 13px;
			}
		}

		&.-pgprice .Colx2c__colLowItem {
			font-size: 13px;
		}

		.Colx2c__colLowItemName {
			&:not(.-scene) {
				width: 4.4em;
			}
			
			&.-scene {
				margin-bottom: 10px;
			}
		}
		
		&.-pgprice .Colx2c__colLowItemName {
			width: 6.8em;
			font-size: 11px;
		}


		.Colx2c__image {
			img {
			}
		}

		.Colx2c__colLow {
			margin-top: 11px;
			padding: 0 6px;
		}
	}

	&.-mt1 {
		margin-top: 30px;
	}
}