/* --------------------
 * Colx1e
 * -------------------- */
.Colx1e {

	&.-mt18 {
		margin-top: 18px;
	}

	&.-mt31 {
		margin-top: 31px;
	}

	.Colx1e__col {
		display: flex;
		justify-content: space-between;
		padding: 10px 9px;
		border: 1px solid #C3C3C3;
		&:not(:first-child) {
			margin-top: 21px;
		}
		&:nth-child(even) {
			background: #F8F5ED; 
		}
	}

	.Colx1e__left {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media print, screen and (max-width: $breakpoint1 - 1){
			width: calc( (100% - 15px) * 0.65);
		}
	}

	.Colx1e__title {
		font-size: 15px;
		font-weight: bold;
		color: #CA0010;
	}

	.Colx1e__tag {
		background: #CA0010;
		color: #fff;
		font-size: 13px;
		font-weight: bold;
		display: flex;
		align-items: center;
		padding: 0 7px;
		flex: 0 0 auto;
	}


	.Colx1e__right {
		@media print, screen and (max-width: $breakpoint1 - 1){
			width: calc( (100% - 15px) * 0.35);
		}
	}

	.Colx1e__image {
		font-size: 0;
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		.Colx1e__col {
			font-size: 15px;
			padding: 24px 28px;
			&:not(:first-child) {
				margin-top: 21px;
			}
		}

		.Colx1e__left {
			width: 422px;
			width: calc( (100% - 34px) * 0.63);
		}

		.Colx1e__title {
			font-size: 35px;
		}

		.Colx1e__tag {
			font-size: 30px;
			padding: 0 7px;
		}

		.Colx1e__intro {
			font-size: 15px;
			margin-top: 12px;
		}

		.Colx1e__right {
			width: calc( (100% - 34px) * 0.37);
		}

		.Colx1e__image {
			img {
				width: 100%;
			}
		}
	}
}