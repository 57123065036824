/* --------------------
 * Paginate
 * -------------------- */
.Paginate {

	.screen-reader-text {
		display: none;
	}

	.nav-links {
		display: flex;
		align-items: center;
    justify-content: center;
	}

	.page-numbers {
		font-size: 16px;
		@include fontMedium;
		text-decoration: none;
		color: #262626;
		display: flex;
		align-items: center;
		&:not(.prev):not(.next) {
			border: 1px solid #C3C3C3;
			padding: 4px 10px;
			margin-right: 5px;
		}


		&.current {
			color: #fff;
			background: #CA0010;
		}

		&.prev {
			margin-right: 10px;
		}

		&.next {
			margin-left: 5px;
		}

	}

	.Paginate__next,
	.Paginate__prev {
		font-size: 0;
		width: 20px;
		img {
			width: 100%;
		}
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){

		.screen-reader-text {
		}

		.nav-links {
		}

		.page-numbers {
			font-size: 21px;
			&:not(.prev):not(.next) {
				border: 1px solid #C3C3C3;
				padding: 6px 12px;
				margin-right: 10px;
			}

			&.current {
			}

			&.prev {
				margin-right: 20px;
			}

			&.next {
				margin-left: 10px;
			}

		}

		.Paginate__next,
		.Paginate__prev {
			width: 24px;
		}
	}
}