/* --------------------
 * Paginateb
 * -------------------- */
.Paginateb {

	display: flex;
	justify-content: space-between;

	.Paginateb__next,
	.Paginateb__prev {
		border: 2px solid #C3C3C3;
		border-radius: 10px;
		overflow: hidden;
		width: 45%;
		flex: 0 0 45%;
	}

	.Paginateb__link {
		display: flex;
		align-items: center;
		height: 100%;
	}

	.Paginateb__arrow {
		width: 20px;
		flex: 0 0 20px;
		align-self: stretch;
		background: #262626;

		position: relative;

		&.-prev {
			&:before {
				display: block;
				width: 5px;
				height: 5px;
				position: absolute;
				top: 50%;
				left: 0;
				transform: translate(100%, -50%) rotate(-45deg);
				content: '';
				border-left: 2px solid #fff;
				border-top: 2px solid #fff;
			}
		}

		&.-next {
			&:before {
				display: block;
				width: 5px;
				height: 5px;
				position: absolute;
				top: 50%;
				right: 0;
				transform: translate(-100%, -50%) rotate(45deg);
				content: '';
				border-right: 2px solid #fff;
				border-top: 2px solid #fff;
			}
		}
	}

	.Paginateb__box {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 5px;
	}

	.Paginateb__image {
		font-size: 0;
		flex: 0 0 55%;
		img {
			width: 100%;
		}
	}

	.Paginateb__text {
		@media print, screen and (max-width: $breakpoint1 - 1){
			flex: 0 0 40%;
		}
		font-size: 10px;
	}

	a {
		text-decoration: none;
		color: #262626;
	}


	// PC style
	@media print, screen and (min-width: $breakpoint1){

		display: flex;
		justify-content: space-between;

		.Paginateb__next,
		.Paginateb__prev {
			width: 300px;
		}

		.Paginateb__link {
			display: flex;
			align-items: center;
		}

		.Paginateb__arrow {
			width: 27px;
			flex: 0 0 27px;

			&.-prev {
				&:before {
					width: 8px;
					height: 8px;
					transform: translate(100%, -50%) rotate(-45deg);
					border-left: 3px solid #fff;
					border-top: 3px solid #fff;
				}
			}

			&.-next {
				&:before {
					width: 8px;
					height: 8px;
					transform: translate(-100%, -50%) rotate(45deg);
					border-right: 3px solid #fff;
					border-top: 3px solid #fff;
				}
			}
		}

		.Paginateb__box {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 10px;
		}

		.Paginateb__image {
			flex: 0 0 138px;
			img {
				width: 100%;
			}
		}

		.Paginateb__text {
			font-size: 14px;
		}

		a {
		}






	}
}