/* --------------------
 * Colx3f
 * -------------------- */
.Colx3f {
	
	display: flex;
	flex-wrap: wrap;

	.Colx3f__col {
		@media print, screen and (max-width: $breakpoint1 - 1){
			width: calc((100% - 5px)/2);
			&:nth-child(even) {
				margin-left: 5px;
			}

			&:nth-child(n+3) {
				margin-top: 5px;
			}
			

		}
	}

	.Colx3f__image {
		font-size: 0;
	}

	// PC style
	@media print, screen and (min-width: $breakpoint1){

		.Colx3f__col {
			width: calc((100% - 22px)/3);
			&:not(:first-child) {
				margin-left: 11px;
			}
		}

		.Colx3f__image {
			img {
				width: 100%;
			}
		}
	}
}